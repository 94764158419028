<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('lotto_game.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('lotto_game.head_list') }} </v-card-title>

        <v-card-actions>
          <v-row no-gutters class="d-flex align-center justify-end">
            <v-btn v-if="canCreate" color="primary" @click="linkToAdd" style="margin-top: 1px"> {{ $t('lotto_game.create')
            }}</v-btn>
            <!-- <v-spacer /> -->
            <!-- <span class="px-3">ค้นหา:</span>
            <div style="width: 150px">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
            </div> -->
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.status`]="{ }">
              <v-btn color="primary" class="white--text text-capitalize" outlined> {{ $t('processing') }} </v-btn>
            </template>
            <template v-slot:[`item.updateResult`]="{ item }">
              <v-btn v-if="canCreate" color="error" class="white--text text-capitalize"
                @click="$router.push(`/predictLotto/update-result/${item.id}`)" :disabled="item.disabledResult">
                {{ $t('lotto_game.header5') }}
              </v-btn>
            </template>
            <template v-slot:[`item.actionDetail`]="{ item }">
              <div class="d-flex flex-row" style="gap: 0.5rem">
                <v-btn color="primary" class="white--text text-capitalize"
                  @click="$router.push(`/predictLotto/update/${item.id}`)">
                  {{ $t('lotto_game.header6') }}
                </v-btn>
                <v-btn color="success" class="white--text text-capitalize"
                  @click="$router.push(`/predictLotto/user/${item.id}`)">
                  {{ $t('lotto_game.user_bet') }}
                </v-btn>
                <v-btn color="success" class="white--text text-capitalize"
                  @click="$router.push(`/predictLotto/result/${item.id}`)">
                  {{ $t('lotto_game.user_win') }}
                </v-btn>

                <v-btn v-if="canCreate" color="error" class="white--text text-capitalize"
                  @click="removePredictLotto(item)">
                  {{ $t('lotto_game.delete') }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.list1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.list2'),
      ],
      headers: [
        {
          text: this.$t('lotto_game.header1'),
          value: 'id',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'ชื่อกิจกรรม',
        //   value: 'title',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('lotto_game.header2'),
          value: 'round',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.header3'),
          value: 'timeStart',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.header4'),
          value: 'timeEnd',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'สถานะ',
        //   value: 'status',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('lotto_game.header5'),
          value: 'updateResult',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.header6'),
          value: 'actionDetail',
          width: '150px',
          align: 'center',
          sortable: false,
        },
      ],
      value: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          this.value = []
          await this.getLottoData(newValue.page, newValue.itemsPerPage)
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'ทายหวย',
              url: window.location.href,
              detail: 'ระบบทายหวย',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getLottoData(page, row) {
      this.value = []
      try {
        let result = await this.$store.dispatch('getLottoList', `page=${page}&rows=${row}`)
        let resData = result.data
        for (let i = 0; i < resData.length; i++) {
          let disabledResult = true

          function isEmptyValues(...values) {
            for (const value of values) {
              if (value === null || value === '') {
                return true
              }
            }
            return false
          }

          // check can send result from time end and result values
          const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
          const timeEnd = moment(resData[i].timeEnd).format('YYYY-MM-DD HH:mm:ss')

          if (
            moment(currentTime).isAfter(timeEnd) &&
            isEmptyValues(resData[i]?.resultTwoDigits) &&
            isEmptyValues(resData[i]?.resultThreeDigitsOne) &&
            isEmptyValues(resData[i]?.resultThreeDigitsTwo)
          ) {
            disabledResult = false
          }

          this.value.push({
            id: resData[i].id || null,
            admin: resData[i].admin || null,
            title: resData[i].title || null,
            round: moment(resData[i].round).format('YYYY-MM-DD') || null,
            timeStart: moment(resData[i].timeStart).format('YYYY-MM-DD HH:mm:ss') || null,
            timeEnd: moment(resData[i].timeEnd).format('YYYY-MM-DD HH:mm:ss') || null,
            typeBetUse: resData[i].typeBetUse || null,
            betUse: resData[i].betUse || null,
            typeReward: resData[i].typeReward || null,
            rewardLastTwoDigits: resData[i].rewardLastTwoDigits || null,
            multiplyTurnOverTwoDigits: resData[i].multiplyTurnOverTwoDigits || null,
            rewardLastThreeDigits: resData[i].rewardLastThreeDigits || null,
            multiplyTurnOverThreeDigits: resData[i].multiplyTurnOverThreeDigits || null,
            resultTwoDigits: resData[i].resultTwoDigits || null,
            resultThreeDigitsOne: resData[i].resultThreeDigitsOne || null,
            resultThreeDigitsTwo: resData[i].resultThreeDigitsTwo || null,
            createdAt: resData[i].createdAt || null,
            disabledResult: disabledResult,
          })
        }
        this.pagination.totalItems = result.count
      } catch (e) { }
    },

    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'predictLotto')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        // this.headers = [
        //   ...this.headers,
        //   {
        //     text: 'ยกเลิกข้อมูล',
        //     value: 'action',
        //     width: '95px',
        //     align: 'center',
        //     sortable: false,
        //   },
        // ]
      }
    },
    async removePredictLotto(data) {
      try {
        let title = `${this.$t('lotto_game.round')} ${data.round}`
        this.$swal
          .fire({
            title: `${this.$t('lotto_game.popup_delete')} ${title}`,
            showCancelButton: true,
            confirmButtonText: this.$t('delete'),
            cancelButtonText: this.$t('cancel'),
            confirmButtonColor: '#ff4c51',
            confirmButtonWidth: 150,
          })
          .then(async result => {
            if (result.isConfirmed) {
              try {
                await this.$store.dispatch('removeLotto', data)
                this.$swal.fire(this.$t('lotto_game.popup_delete_complete'), '', 'success')
                await this.getLottoData(this.pagination.page, this.pagination.itemsPerPage)
              } catch (e) {
                if (
                  !e.message.includes('Unexpected token') ||
                  !e?.includes('Unexpected token') ||
                  !e.message.includes('Unexpected identifier')
                ) {
                  this.$swal.fire({
                    icon: 'error',
                    title: e.message,
                  })
                }
              }
            }
          })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async searchKeyword(value) {
      this.loading = true
      this.value = []
      let result = {}
      if (value) {
        result = await this.$store.dispatch(
          'getLottoList',
          `page=1&rows=${this.pagination.rowsPerPage}&search=${value}`,
        )
      } else {
        result = await this.$store.dispatch('getLottoList', `page=1&rows=${this.pagination.rowsPerPage}`)
      }
      this.pagination.totalItems = result.count
      this.value = result.data
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    linkToAdd() {
      this.$router.push({ name: 'predict-lotto-add' })
    },
    linkToEdit(id) {
      this.$router.push({ name: 'predict-lotto-detail', params: { id } })
    },
  },
}
</script>
